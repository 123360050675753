import Head from 'next/head';
const seoData = {
  title: ['Chicking | Crispy Halal Fried Chicken, Burgers & More'],
  description: ['Discover the ultimate fried chicken experience at Chicking! Enjoy halal-certified fast food, from crispy chicken to juicy burgers. Visit us or order online now.', 'Craving delicious halal fried chicken? Chicking serves irresistible flavors with a halalcertified food menu. Order online or dine in for a taste of perfection!', 'Experience the perfect crunch and juiciness. 100% halal-certified crispy fried chicken, burgers & more. Visit your nearest Chicking outlet or order online now.', 'Crispy, juicy, and packed with flavor—Chicking serves the top-quality halal fried chicken near you! Order online or visit your nearest Chicking outlet today!'],
  keywords: ['halal fried chicken', 'fried chicken', 'fried chicken near me', 'best fried chicken near me', 'best fried chicken in town', 'fried chicken bucket', 'grilled chicken', 'spicy grilled chicken', 'burger', 'chicking burger', 'chicking', 'chicking offers today', 'chicken restaurants near me', 'fried chicken offers near me', 'buckets chicken', 'nearest fried chicken shop', 'crispy fried chicken', 'crunchy fried chicken', 'chicking near me', 'chicking menu', 'chicking wednesday offers', 'seafood burgers', 'tandoori strips', 'burger meals', 'broasted near me', 'best southern fried chicken near me', 'fried chicken combo meals', 'fried chicken value meals']
};
const SeoManager = () => {
  return <Head data-sentry-element="Head" data-sentry-component="SeoManager" data-sentry-source-file="index.jsx">
            <title>{seoData.title[0]}</title>
            <meta name="description" content={seoData.description[0]} data-sentry-element="meta" data-sentry-source-file="index.jsx" />
            <meta name="keywords" content={seoData.keywords.join(', ')} data-sentry-element="meta" data-sentry-source-file="index.jsx" />
            <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="index.jsx" />
        </Head>;
};
export default SeoManager;