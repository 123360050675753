import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loaders: [],
    feedbacks: [],
    home: {},
    countries: [],
    masterCountries: [],
};

const commonSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        addLoader(state, action) {
            return {
                ...state,
                loaders: [...state.loaders, action.payload],
            };
        },
        removeLoader(state, action) {
            return {
                ...state,
                loaders: state.loaders.filter((loader) => loader !== action.payload),
            };
        },
        clearAllLoaders(state) {
            console.warn('clearAllLoaders');
            return {
                ...state,
                loaders: [],
            };
        },
        addHomeData(state, action) {
            return {
                ...state,
                home: { ...state.home, ...action.payload?.home },
                companyDetails: { ...state.companyDetails, ...action.payload?.companyDetails },
            };
        },
        addNearByStores(state, action) {
            return {
                ...state,
                nearByStores: action.payload,
            };
        },
        setCountries(state, action) {
            return {
                ...state,
                countries: action.payload,
            };
        },
        clearCountries(state) {
            return {
                ...state,
                countries: [],
            };
        },
        setMasterCountries(state, action) {
            return {
                ...state,
                masterCountries: action.payload,
            };
        },
        clearMasterCountries(state) {
            return {
                ...state,
                masterCountries: [],
            };
        },
    },
});

export const {
    addLoader,
    removeLoader,
    clearAllLoaders,
    addHomeData,
    addNearByStores,
    setCountries,
    clearCountries,
    setMasterCountries,
    clearMasterCountries,
} = commonSlice.actions;
export default commonSlice.reducer;
